export default function getGeoLocation(callback) {
  if (navigator?.geolocation) {
    navigator?.geolocation?.getCurrentPosition(
      (position) => {
        const coords = {
          lat: position?.coords?.latitude,
          lang: position?.coords?.longitude,
        };
        callback(coords); // Pass the coordinates to the callback
      },
      (error) => {
        callback({}); // Pass an empty object in case of error
      }
    );
  } else {
    callback({}); // Pass an empty object if geolocation is not supported
  }
}
